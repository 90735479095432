.home-container {
    max-width: 1680px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

.discover-homepage .home-container {
    /* -webkit-flex-direction: column; */
    flex-direction: column;
    /* -webkit-align-items: stretch; */
    align-items: stretch;
}

.discover-diamond .home-container {
    /* display: -webkit-flex; */
    display: flex;
    padding: 83px 20px;
    /* -webkit-justify-content: space-between; */
    /* -webkit-align-items: center; */
    justify-content: space-between;
    /* align-items: center; */
}


/*Style For Heading Content*/

.choose-style .choose-title,
.discover-homepage .discover-title,
.new-trend .trend-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}

.choose-style .heading-section,
.discover-homepage .heading-section,
.new-trend .heading-section {
    -webkit-flex-basis: 58%;
    flex-basis: 58%;
    max-width: fit-content;
}

.discover-diamond .heading-section {
    flex-basis: 16.5%;
    max-width: 16.5%;
}


.heading-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 15px;
}

.hr-line {
    width: calc((100% - 46px) / 2);
    border-top: 2px solid #363636;
    opacity: 0.5;
    height: 1px;
}

.diamond-icon {
    background: url(/assets/images/new_template/images/Icon_jewelry.svg) no-repeat center;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 15px;
}


/*Style For Shop All content*/
.choose-title .nav-content,
.discover-title .nav-content,
.new-trend .nav-content {
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-align-items: center; */
    align-items: center;
}

.choose-title .para-one,
.discover-title .para-one,
.new-trend .para-one {
    font-size: 20px !important;
}

.para-one {
    line-height: 30px !important;
    color: #505050;
}

.normal-para,
.para-one {
    font-size: 16px !important;
    font-family: gillSans !important;
    margin: 0;
}

.custom-arrow .nav-arrow {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    user-select: none;
    margin-left: 10px;
    margin-top: -9px;
}

.custom-arrow .nav-arrow.right-arrow {
    background-image: url(/assets/images/new_template/images/nextarrow.svg);
}

.custom-arrow .nav-arrow.left-arrow {
    background-image: url(/assets/images/new_template/images/nextarrow.svg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}


/*Style for Image content*/
.discover-homepage .discover-contents {
    /* -webkit-flex-basis: 100%; */
    flex-basis: 100%;
    max-width: 100%;
}


.discover-diamond .discover-item:last-child {
    margin-right: 0;
    padding: 15px;
}

.discover-homepage .discover-item {
    border: 0;
    width: 100%;
    cursor: pointer;
}

.discover-diamond .discover-item {
    /* border: 1px solid #707070; */
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-flex-direction: column; */
    flex-direction: column;
    /* -webkit-align-items: center; */
    align-items: center;
    /* width: calc((100% - 10%) / 6); */
    /* margin-right: 2%; */
}


.discover-homepage .discover-item .discover-img {
    width: 100%;
    max-width: 100%;
    background: #fbf8f5;
    background-blend-mode: saturation;
}

.discover-homepage .discover-item .discover-img img {
    mix-blend-mode: multiply;
}

.discover-diamond .discover-item .discover-img img {
    display: block;
    margin: auto;
}

.discover-homepage .discover-name {
    text-align: left;
    padding: 20px 0;
}

.discover-diamond .discover-name {
    width: 100%;
    /* text-align: center; */
    /* padding: 5px 10px 25px; */
}

.discover-homepage .discover-item .para-one {
    font-size: 24px !important;
    line-height: 38px;
    font-family: bigCalsonMedium !important;
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
}

.discover-homepage .discover-item:hover .para-one::before {
    width: 100%;
}

.discover-homepage .discover-item .para-one::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 0;
    bottom: 0;
    height: 3px;
    background-color: #722257;
    /* -webkit-transition: all 0.3s ease-in-out; */
    transition: all 0.3s ease-in-out;
}


@media screen and (min-width: 478px)and(max-width: 767px) {

  .discover-diamond .discover-item:last-child {
      margin-right: 0;
      padding: 15px;
  }

    .choose-style .heading-section,
    .discover-homepage .heading-section,
    .new-trend .heading-section {
        margin-bottom: 0;
    }

    .choose-style .title-normal,
    .discover-homepage .heading-section .title-normal {
        font-size: 12px !important;
        line-height: 18px !important;
        text-align: left;
    }

    .discover-homepage .discover-item .para-one {
        font-size: 18px !important;
        line-height: 22px !important;
    }

    .choose-style .container,
    .discover-homepage .container,
    .new-trend .container {
        padding: 20px;
    }

    .discover-homepage .home-container {
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: stretch;
        align-items: stretch;
    }

    .discover-diamond .home-container {
        -webkit-justify-content: center;
        justify-content: center;
        padding: 25px 20px;
    }

    .choose-style .choose-title,
    .discover-homepage .discover-title,
    .new-trend .trend-title {
        margin-bottom: 15px;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }

    .choose-style .choose-title .para-one,
    .discover-homepage .discover-title .para-one,
    .new-trend .trend-title .para-one {
        font-size: 12px !important;
        line-height: 16px;
    }


    .choose-style .nav-arrow,
    .discover-homepage .nav-arrow,
    .new-trend .nav-arrow {
        width: 10px;
        height: 10px;
        margin-top: -3px;
    }

    .title-normal {
        font-size: 24px;
        line-height: 26px;
        text-align: center;
    }

    .heading-section .heading-title::after {
        content: '';
        background: url(/assets/images/new_template/images/right.svg) no-repeat center/contain;
        width: 0px;
        height: 10px;
        padding-right: 10px;
        display: inline-block;
        transform: rotate(90deg);
        transition: all .3s ease-in-out;
        cursor: pointer;
    }

    .discover-diamond .discover-item:last-child {
        margin-right: 0;
        padding: 0px;
    }

}

@media screen and (max-width: 1023px) {
    .discover-diamond .home-container {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
        padding: 40px 20px;
    }

    .discover-homepage .discover-title {
        margin-bottom: 30px;
    }

    .choose-style .title-normal,
    .discover-homepage .title-normal {
        font-size: 24px !important;
        line-height: 28px !important;
    }

    .discover-homepage .discover-item .para-one {
        font-size: 20px !important;
        line-height: 24px !important;
    }
}

@media screen and (max-width: 1400px) {
    .discover-diamond .home-container {
        padding: 40px 20px;
    }

    .discover-diamond .heading-section {
        flex-basis: 20%;
        max-width: 20%;
    }
}

@media screen and (max-width: 1760px) {
    .discover-diamond .heading-section .title-normal {
        font-size: 26px;
        line-height: 34px;
    }
}
