.homepage-banner-wrapper .slick-dots {
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  z-index: 10;
  text-align: center;
  margin: 0;
  padding: 0;
}

.homepage-banner-wrapper .slick-dots li {

  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 27px !important;
  padding: 0;
  cursor: pointer;
}

.homepage-banner-wrapper .slick-dots li button {
  list-style: none;
  width: 50px;
  height: 2px;
  border-radius: 0;
  background: #fff;
  opacity: .6;
  cursor: pointer;
  padding: 0;
}

.homepage-banner-wrapper .slick-dots li button:before {
  opacity: 0;
}

.homepage-banner-wrapper .slick-dots li.slick-active button {
  opacity: 1;
}

.homepage_banner .homepage-banner_wrapper {
  position: relative;
  /* display: _webkit_flex; */
  display: flex;
}

.homepage-banner .banner-left {
  /* max-width: 60%; */
  width: 100%;
}

.homepage-banner .banner-home img {
  max-height: 600px;
  height: 100%;
  width: 100%;
}

.homepage-banner .banner-right {
  max-width: 40%;
  width: 100%;
}

.homepage-banner .mobile-banner {
  display: none;
}

.homepage-banner .banner-content {
  background-color: #722257;
  color: #fbf8f5;
  font-family: bigCalsonMedium;
  -webkit-filter: drop-shadow(0 23px 36px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 23px 36px rgba(0, 0, 0, 0.16));
  max-width: 41.48%;
  width: 100%;
  padding: 5.45% 0 2.7%;
  position: absolute;
  top: 8.411%;
  left: 45.2%;
  text-align: center;
}


@media screen and (max-width: 767px) {
  .homepage-banner .mobile-banner {
    display: block !important;
    width: 100%;
  }

  .homepage-banner .mobile-banner img {
    width: 100%;
  }

  .homepage-banner-wrapper {
    display: none !important;
  }

  .mobile-banner .slick-dots {
    bottom: 5px;
    display: inline-flex !important;
    margin: 0px 35px;
  }

  .mobile-banner .slick-dots li {
    margin: 0 30px;
  }

  .mobile-banner .slick-dots li button {
    list-style: none;
    width: 50px;
    height: 2px;
    border-radius: 0;
    background: #fff;
    opacity: .6;
    cursor: pointer;
    padding: 0;
  }

  .mobile-banner .slick-dots li button:before {
    opacity: 0;
  }

  .mobile-banner .slick-dots li.slick-active button {
    opacity: 1;
  }

}