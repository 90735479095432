.faqdot1 {
    width: 10px;
    height: 10px;
    animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.faqdot2 {
    width: 10px;
    height: 10px;
    animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.faqdot3 {
    width: 10px;
    height: 10px;
    animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}

@keyframes ScaleInOut {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1.5);
    }
}