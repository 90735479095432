.leadership-section {
    /* background-color: #722257; */
    margin-bottom: 80px;
}

.leadership-container .heading-section {
	max-width: 285px;
	margin: 0 auto;
}

.leadership-container .para-one{
    font-size: 16px !important;
	font-family: gillSans !important;
	margin: 0;
	line-height: 30px !important;
	color: #505050;
    text-align: inherit;

}
