.banner-section {
    width: 100%;
    max-width: 1640px;
    margin: 90px auto 0;
}


.slick-slide img {
    display: block;
}

.slick-slide {
    position: relative;
}

.banner-section img,
.bottom-banner img {
    width: 100%;
}

.banner_slider .slick-dots {
    position: absolute;
    bottom: 35px;
    left: 0;
    width: 100%;
    z-index: 10;
    text-align: center;
    margin: 0;
    padding: 0;
}

.banner_slider .slick-dots li {
    margin: 0 30px;
}

.banner_slider .slick-dots li button {
    list-style: none;
    width: 50px;
    height: 2px;
    border-radius: 0;
    background: #fff;
    margin: 5px;
    opacity: .6;
    cursor: pointer;
    padding: 0;
}

.banner_slider .slick-dots li button:before {
	opacity: 0;
}

.banner_slider .slick-dots li.slick-active button {
    opacity: 1;
}


/*Banner Content CSS*/

.banner-text {
    position: absolute;
    right: 10%;
    bottom: 15%;
}

.banner-text span {
    color: #fff;
    letter-spacing: 3.36px;
    margin-bottom: 12px;
    display: block;
    font-family: gillSans !important;
}

.banner-text h2 {
    font-size: 40px !important;
    color: #fff !important;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 35px;
    font-family: gillSans !important;
}

.banner-text button {
    background: 0 0;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 55px;
    font-size: 12px;
    letter-spacing: 2.88px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.banner-text button:hover {
    color: #722257;
    border-color: #fff;
    background-color: #fff;
}

@media screen and (max-width: 1023px) {
    .banner-text {
        right: 6%;
        bottom: 22%;
    }

    .banner-text span {
        letter-spacing: 1px;
    }

    .banner-text h2 {
        font-size: 28px;
    }

    .banner-text button {
        padding: 18px 35px;
    }

}

@media screen and (max-width: 767px) {
    .banner_slider .slick-dots {
        bottom: 5px;
        display: inline-flex !important;
    }

    .banner_slider .slick-dots li {
        margin: 0 30px;
    }
    
    .banner_slider .slick-dots li button {
        list-style: none;
        width: 50px;
        height: 2px;
        border-radius: 0;
        background: #fff;
        margin: 5px;
        opacity: .6;
        cursor: pointer;
        padding: 0;
    }
    
    .banner_slider .slick-dots li button:before {
        opacity: 0;
    }
    
    .banner_slider .slick-dots li.slick-active button {
        opacity: 1;
    }

    .banner-text span {
        letter-spacing: .5px;
        margin-bottom: 10px;
        font-size: 10px;
    }

    .banner-text h2 {
        font-size: 18px !important;
        margin-bottom: 8px;
    }

    .banner-text button {
        padding: 11px 14px;
        font-size: 10px;
        letter-spacing: 2px;
    }
    
}