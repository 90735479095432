.top-info-bar {
    background-color: #722257;
}

@media screen and (max-width: 767px) {
    .top-text {
        flex-direction: column;
    }
}

@media screen and (max-width: 1300px) {
    .top-text {
        max-width: 100%;
    }
}

.top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1420px;
    margin: 0 auto;
}

.top-text {
    background-color: #722257;
    padding: 15px;
}

@media screen and (max-width: 1023px) {
    .head-contact-detail {
        justify-content: start;
    }
}

@media screen and (max-width: 1023px) {
    .top-text>* {
        max-width: 49%;
    }
}


.head-contact-detail {
    max-width: 376px;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 1023px) {
    .head-contact-detail a:first-child {
        margin-left: 0;
    }
}

.head-contact-detail a {
    color: #000;
    font-size: 14px;
    text-decoration: none;
}

@media screen and (max-width: 1023px) {
    .head-contact-detail a {
        margin-left: 15px;
    }
}

@media screen and (max-width: 1023px) {
    .top-text span {
        font-size: 12px;
    }
}

.top-text span {
    text-align: center;
    color: #fff;
    /* font-size: 14px; */
    font-family: gillSans;
    display: block;
}

@media screen and (max-width: 1023px) {
    .top-text>* {
        max-width: 49%;
    }
}

@media screen and (max-width: 767px) {
    .top-text>* {
        max-width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .head-contact-detail {
        order: 2;
        margin-top: 15px;
    }
}