@font-face {
  font-family: big-caslon;
  src: url(/assets/fonts/new_template/fonts/big-caslon.woff2);
  font-display: swap;
}

@font-face {
  font-family: gillSans;
  src: url("/assets/fonts/new_template/fonts/Gill-Sans.woff");
  font-display: swap;
}

@font-face {
  font-family: gillSansMedium;
  src: url("/assets/fonts/new_template/fonts/Gill-Sans-Medium.woff");
  font-display: swap;
}

body {
  margin: 0;
  font-family: gillSans, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.header-container {
  background-color: #fbf8f5;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #722257;
}

.main-contenr {
  min-height: 600px;
}

.header {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto;
  margin: 0 auto;
  max-width: 1550px;
  padding: 20px 20px 20px;
}

.search-links {
  grid-column: 3;
}

.logo img+img {
  margin-top: 20px;
}

ul {
  margin: 0;
  padding: 0;
}

.top-links li {
  display: inline-block;
}

.logo {
  text-align: center;
  max-width: 206px;
  /* grid-column: 2; */
  grid-row: 1 / span 2;
  margin-top: 10px;
}

.logo img {
  vertical-align: baseline;
}

.top-links {
  grid-column: 3;
  text-align: right;
  align-self: end;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

.head-contact-detail a {
  color: #000;
  font-size: 14px;
  text-decoration: none;
}

.topnav {
  align-self: end;
}

.homepage-1 .topnav {
  position: relative;
}

.homepage-1 .topnav .dropdown-content {
  left: 0;
  right: unset;
}

/* .topnav li {
  margin-right: 30px;
  display: inline-block;
  cursor: pointer;
} */

.search-links {
  grid-column: 3;
  display: flex;
}

.search-links {
  grid-column: 3;
  display: flex;
}

.all-links {
  display: flex;
}

input#search {
  height: 40px;
  border-radius: 26px;
  color: #a3a3a3;
  font-size: 16px;
  padding: 0 25px;
  border: 1px solid #e2e2e2;
  max-width: 400px;
  min-width: 400px;
  outline: 0;
  font-family: gillSans;
}

input#search::placeholder {
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.search-links {
  grid-column: 3;
  display: flex;
  align-items: flex-end;
}

.top-links li {
  margin-left: 10%;
}

a.contact {
  border-right: 1px solid #707070;
  margin-right: 20px;
  padding-right: 20px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}

.head-contact-detail span {
  padding-left: 9px;
}

.search-box {
  position: relative;
  margin: 0 !important;
}

button.action.search {
  position: absolute;
  border: none;
  right: 15px;
  top: 11px;
  background: url("/assets/images/new_template/images/group-197.svg") no-repeat;
  font-size: 0;
  width: 17px;
  height: 19px;
}

.footer-element {
  display: flex;
  justify-content: flex-end;
  background-color: #fbf8f5;
}

.left-column {
  display: flex;
  min-height: 540px;
  max-width: 850px;
  align-items: center;
  flex-wrap: wrap;
}

.right-column {
  background: #722257;
  color: #fff;
  max-width: 800px;
  min-width: 42%;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 540px;
  display: flex;
}

.right-inner {
  max-width: 380px;
  margin: 0 auto;
  text-align: left;
}

.right-inner h2 {
  font-size: 38px;
  font-family: Big Caslon, Medium;
  font-weight: 400;
}

.right-inner span {
  font-size: 18px;
  font-family: Big Caslon, Medium;
}

.payment-links {
  margin-top: 65px;
}

.newsletter-outer {
  margin-top: 30px;
}

.newsletter {
  margin: 35px 0 65px;
  position: relative;
  max-width: 380px;
}

h3.footer-toggle {
  margin: auto;
  font-family: gillSansMedium !important;
  font-weight: 500;
}

.inner a {
  margin-top: 13.5px;
  display: block;
  font-family: gillSans;
}

.column {
  width: 33.33%;
  align-self: flex-end;
}

input#newsletter {
  border: none;
  background: 0 0;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  width: 100%;
  outline: 0;
}

input#newsletter::placeholder {
  opacity: 50%;
}

::placeholder {
  color: #fff;
  opacity: 1;
}

.social-links {
  max-width: 265px;
  display: flex;
  justify-content: space-between;
}

.social-links a {
  width: 20px;
  background: url("/assets/images/new_template/images/social-icon.svg") no-repeat;
  height: 20px;
  display: inline-block;
}

.social-links a.social-2 {
  background-position: -39px 0;
}

.social-links a.social-3 {
  background-position: -80px 0;
}

.social-links a.social-4 {
  background-position: -121px 0;
  width: 22px;
}

.social-links a.social-5 {
  background-position: -165px 0;
}

.social-links a.social-6 {
  background-position: -207px 0;
}

.social-links a.social-7 {
  background-position: -247px 0;
}

button.action.subscribe.primary {
  position: absolute;
  border: none;
  right: 0;
  top: 5px;
  background: url("/assets/images/new_template/images/group-198.svg") no-repeat;
  width: 19px;
  height: 12px;
  cursor: pointer;
}

button.action.subscribe.primary span {
  font-size: 0;
}

@media screen and (min-width: 768px) {
  ul.inner {
    display: block !important;
  }

  /* .topnav .icon {
    display: none;
  } */
}

@media screen and (max-width: 1300px) {
  .topnav {
    align-self: end;
    grid-row: 3;
    grid-column: 1 / span 3;
    width: 100%;
    margin-top: 25px;
  }

  .top-links {
    display: none;
  }

  input#search {
    min-width: 100%;
    width: 265px;
  }

  .footer-element {
    flex-direction: column;
  }

  .right-column {
    order: -1;
    justify-content: unset;
    max-width: 100%;
  }

  .left-column {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  .right-inner {
    max-width: 90%;
    margin: 0 auto;
    text-align: left;
  }

  .column {
    width: 33.33%;
    align-self: unset;
  }

  .header {
    margin: 0 20px;
  }

  .search-links {
    align-items: center;
    grid-row: 2;
    /* justify-content: space-between; */
    grid-column: 2 / span 2;
  }
}

@media screen and (max-width: 1024px) {

  .header-container {
    padding-top: 20px;
  }

  /* .head-contact-detail {
    order: -1;
    text-align: right;
  } */

  .left-column {
    flex-direction: column;
    min-height: auto;
  }

  ul.inner {
    display: none;
  }

  .topnav {
    margin-top: 0;
  }

  .topnav ul {
    display: none;
  }

  .topnav.responsive ul {
    display: block;
    position: fixed;
    background: #722257;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    padding: 40px 20px;
    transition: all 0.3s ease;
    overflow-y: auto;
  }

  .topnav.responsive {
    position: relative;
  }

  /* .topnav .icon {
    position: absolute;
    left: 0;
    top: 0;
  } */

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }

  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }

  .logo {
    margin: 15px auto 30px;
  }

  .search-links {
    order: 1;
    justify-content: space-between;
  }

  .icon span {
    width: 20px;
    height: 2px;
    background-color: #722257;
    margin: 3px 0;
    display: block;
  }

  .topnav.responsive .icon {
    position: fixed;
    left: 20px;
  }

  .topnav.responsive a.icon:after {
    content: "X";
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 25px;
  }

  .topnav.responsive .icon span {
    font-size: 0;
    visibility: hidden;
  }

  /* .topnav li {
    margin-right: 0;
    display: block;
    text-align: left;
    margin: 20px 0;
  } */

  .search-box {
    width: 54%;
  }

  .column {
    width: 100%;
    align-self: unset;
    margin: 23px 0 0;
    text-align: center;
    border-bottom: 1px solid;
    padding-bottom: 10px;
  }

  .column-last {
    margin: 40px 0;
  }

  h3.footer-toggle {
    cursor: pointer;
  }
}

@media screen and (max-width: 1300px) {

  /* .head-contact-detail {
    display: none;
  } */
  /* .topnav li,
  a.dropbtn {
    font-size: 12px;
  } */

  /* .topnav li {
    margin-right: 30px;
  } */

  .header {
    padding: 0;
  }

  .search-box {
    position: relative;
    margin: 0 !important;
    grid-column: 2;
    grid-row: 2;
  }

  .logo {
    /* grid-column: 1; */
    width: 123px;
    grid-row: 1 / span 3;
  }

  .logo img+img {
    margin-top: 5px;
  }

  .topnav {
    grid-row: 4;
  }
}

@media screen and (min-width: 768px) {
  .left-column {
    padding: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .logo {
    order: -1;
    margin: 0 auto 10px;
    width: 123px;
  }

  .search-box {
    width: 100%;
  }

  /* .topnav li {
    margin-right: 0;
    display: block;
  } */

  a.cart-link {
    border-left: none;
    padding-left: 0;
  }

  .right-inner span {
    font-size: 16px;
  }

  /* .head-contact-detail {
    display: none;
  } */

  .all-links {
    position: absolute;
    top: 0;
    right: 0;
  }

  .search-links .all-links {
    margin-left: 15px;
  }

  .all-links .wishlist {
    margin-left: 0;
  }

  .all-links .account,
  .all-links .cart {
    margin-left: 15px;
  }

  .newsletter-outer {
    margin-top: 22px;
  }

  .right-inner h2 {
    font-size: 28px;
  }

  .right-column {
    min-height: 100%;
    padding: 50px 0;
  }

  .homepage-1 #search {
    margin-top: 20px;
  }

  .homepage-1 button.action.search {
    top: 50%;
  }
}