.image-gallery {
    margin-bottom: 90px;
}

.image-gallery .heading-section {
    max-width: fit-content;
    width: 100%;
    margin-bottom: 44px;
}

@media screen and (max-width: 767px) {
    .image-gallery {
        margin-bottom: 20px;
    }

    .image-gallery .heading-section {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 960px) {
    .image-gallery {
        /* margin-bottom: 30px; */
    }
}