section.gift-ideas-section {
    background-color: #fbf8f5;
    padding: 70px 20px 40px;
    margin-bottom: 90px;
}

.gift-ideas-container {
    max-width: 1640px;
    margin: 0 auto;
}

.gift-ideas-container .heading-section {
    max-width: 180px;
}

.heading-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 15px;
}

/*gift content css*/

.gift-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;
}

.gift-content a {
    width: 100%;
    padding: 15px;
    border: 1px solid #722257;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
    transition: 0.3s;
    font-size: 21px;
    vertical-align: middle;
    height: 61px;
    max-width: 22.56%;
    color: #000 !important;
    font-family: gillSans !important;
}

a {
    text-decoration: none;
    color: #000;
}

.gift-content a:hover {
    background-color: #722257 !important;
    color: #fff !important;
}

@media screen and (min-width: 768px) {
    .gift-content a:not(:first-child) {
        margin-left: 3.25%;
    }

    .gift-content a:nth-child(5n + 5) {
        margin-left: 0;
    }
}


@media screen and (max-width: 767px) {

    section.gift-ideas-section {
        margin-bottom: 25px;
    }

    .title-normal {
        font-size: 24px !important;
        line-height: 26px;
        text-align: center;
    }

    .heading-section .heading-title::after {
        content: '';
        background: url(/assets/images/new_template/images/right.svg) no-repeat center/contain;
        width: 11px;
        height: 10px;
        padding-right: 10px;
        display: inline-block;
        transform: rotate(90deg);
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
}


@media screen and (min-width : 600px) and (max-width: 759px){

    .gift-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 37px;
    }

    .gift-content a:not(:first-child) {
        margin-left: 3.25%;
    }

    .gift-content a:nth-child(5n + 5) {
        margin-left: 0;
    }

    .gift-content a {
        font-size: 15px !important;
    }
}


@media screen and (max-width: 599px) {
    .gift-content a {
        max-width: 100%;
    }
}

@media screen and (max-width: 1300px) {
    .gift-content a {
        font-size: 17px;
    }
}