.uk-breadcrumb {
    padding: 5px !important;
    list-style: none;
}

.uk-breadcrumb>* {
    display: contents;
}

.uk-breadcrumb>*>* {
    font-size: 14px;
    font-size: 0.875rem;
    color: #999 !important;
    font-family: gillSans !important;

}

.uk-breadcrumb>*> :hover {
    color: #722257 !important;
    text-decoration: none;
}

.uk-breadcrumb> :last-child>span {
    color: #722257;
}

.uk-breadcrumb> :nth-child(n+2):not(.uk-first-column):before {
    content: "/";
    display: inline-block;
    margin: 0 20px 0 16px;
    font-size: 14px;
    font-size: 0.875rem;
    color: #999;
}