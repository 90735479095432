@media screen and (max-width: 1024px) {
    li {
        display: block !important;
    }
}

ul.uk-accordion-menu-bar p.none-link {
    font-size: 18px;
    color: #fff !important;
}

.uk-offcanvas-bar .uk-accordion-title {
    color: #fff !important;
}

.uk-dropdown-nav>li>a {
    color: #999 !important;
}

.uk-offcanvas-bar .uk-accordion-title::before {
    position: initial;
}

a.side-bar::before {
    top: 2rem;
    background-color: rgb(114, 34, 87) !important;
    transition: 0.5s ease;
}