.service-section {
	background-color: #fbf8f5;
	padding: 70px 20px;
	margin-bottom: 80px;
}

.service-contents {
	grid-column: 3;
	display: flex;
	align-items: flex-end;
}

/* .service-item{
    text-align: center;
    padding: 40px;
} */

.service-item {
	text-align: center;
	border: 1px solid #722257;
	box-shadow: 2px 4px 5px #722257a6;
	padding: 10px;
	vertical-align: middle;
	max-height: 260px;
	font-size: 12px;
	max-width: 432px;
	width: 100%;
	margin: 0 15px 0px;
	transition: all 0.3s ease-in-out;
}

.service-text {
	text-align: center;
	margin-top: 15px;
}

@media only screen and (max-width: 650px) {

	.service-contents {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.service-item {

		margin: 0 15px 20px;

	}

}


@media only screen and (min-width : 650px) {
	.service-item {
		max-height: 355px;
	}
}