/*! CSS Used from: http://localhost:8000/src/css/main.css */
a {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    color: #722257;
    text-decoration: none !important;
}

b {
    font-weight: bolder;
}

svg {
    vertical-align: middle;
}

svg {
    max-width: 100%;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

ul,
fieldset {
    margin: 0 0 20px 0;
}

ul {
    padding-left: 30px;
}

hr {
    overflow: visible;
    text-align: inherit;
    margin: 0 0 20px 0;
    border: 0;
    border-top: 1px solid #e5e5e5;
}

*+hr {
    margin-top: 20px;
}

:focus {
    outline: none;
}

::-moz-selection {
    background: #39f;
    color: #fbf8f5;
    text-shadow: none;
}

::selection {
    background: #39f;
    color: #fbf8f5;
    text-shadow: none;
}

.uk-icon {
    margin: 0;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
}

.uk-icon::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
    stroke: currentcolor;
}

.uk-icon>* {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.uk-radio,
.uk-checkbox {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    font: inherit;
}

.uk-fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

.uk-radio,
.uk-checkbox {
    display: inline-block;
    height: 16px;
    width: 16px;
    overflow: hidden;
    margin-top: -4px;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #cccccc;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: background-color, border;
    -o-transition-property: background-color, border;
    transition-property: background-color, border;
}

.uk-radio {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.uk-radio:focus,
.uk-checkbox:focus {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border-color: #ccc;
}

.uk-radio:checked,
.uk-checkbox:checked {
    background-color: #722257;
    border-color: transparent;
}

.uk-radio:checked:focus,
.uk-checkbox:checked:focus {
    background-color: #722257;
}

.uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-radio:disabled,
.uk-checkbox:disabled {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
}

.uk-radio:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-button {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -webkit-transition-property: color, background-color, border-color;
    -o-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
}

.uk-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.uk-button:hover {
    text-decoration: none;
}

.uk-button-default {
    background-color: transparent;
    color: #333;
    border: 1px solid #e5e5e5;
}

.uk-button-default:hover {
    background-color: transparent;
    color: #333;
    border-color: #b2b2b2;
}

.uk-button-default:active {
    background-color: transparent;
    color: #333;
    border-color: #999999;
}

.uk-button-primary {
    background-color: #722257;
    color: #fff;
    border: 1px solid transparent;
}

.uk-button-primary:hover {
    background-color: #000000;
    color: #fff;
}

.uk-button-primary:active {
    background-color: #4b1639;
    color: #fbf8f5;
}

.uk-button-default:disabled,
.uk-button-primary:disabled {
    background-color: transparent;
    color: #999;
    border-color: #e5e5e5;
}

.uk-close {
    color: #999;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -webkit-transition-property: color, opacity;
    -o-transition-property: color, opacity;
    transition-property: color, opacity;
}

.uk-close:hover {
    color: #722257;
}

.uk-accordion {
    padding: 0;
    list-style: none;
}

.uk-accordion> :nth-child(n+2) {
    margin-top: 20px;
}

.uk-accordion-title {
    display: block;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #333;
    overflow: hidden;
}

.uk-accordion-title:before {
    content: "";
    width: 1.4em;
    height: 1.4em;
    margin-left: 10px;
    float: right;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.uk-open>.uk-accordion-title:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23722257%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-accordion-title:hover {
    color: #722257;
    text-decoration: none;
}

.uk-accordion-content {
    display: flow-root;
    margin-top: 20px;
}

.uk-accordion-content> :last-child {
    margin-bottom: 0;
}

.uk-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 15px;
    background: #000000;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media (min-width: 640px) {
    .uk-modal {
        padding: 50px 30px;
    }
}

@media (min-width: 960px) {
    .uk-modal {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.uk-modal.uk-open {
    opacity: 1;
}

.uk-modal-dialog {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    max-width: 100% !important;
    background: #fff;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: opacity, transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

.uk-open>.uk-modal-dialog {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.uk-modal-body {
    display: flow-root;
    padding: 20px 20px;
}

@media (min-width: 640px) {
    .uk-modal-body {
        padding: 30px 30px;
    }
}

.uk-modal-body> :last-child {
    margin-bottom: 0;
}

[class*='uk-modal-close-'] {
    position: absolute;
    z-index: 1010;
    top: 10px;
    right: 10px;
    padding: 5px;
}

[class*='uk-modal-close-']:first-child+* {
    margin-top: 0;
}

.uk-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.uk-grid>* {
    margin: 0;
}

.uk-grid>*> :last-child {
    margin-bottom: 0;
}

.uk-grid {
    margin-left: 0px;
}

.uk-grid>* {
    padding-left: 0px;
}

.uk-grid>.uk-grid-margin,
*+.uk-grid-margin {
    margin-top: 0px;
}

@media (min-width: 1200px) {
    .uk-grid {
        margin-left: -40px;
    }

    .uk-grid>* {
        padding-left: 40px;
    }

    .uk-grid>.uk-grid-margin,
    *+.uk-grid-margin {
        margin-top: 40px;
    }
}

.uk-grid-small {
    margin-left: -15px;
}

.uk-grid-small>* {
    padding-left: 15px;
}

[class*='uk-child-width']>* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.uk-child-width-auto>* {
    width: auto;
}

@media (min-width: 960px) {
    .uk-child-width-1-2\@m>* {
        width: 50%;
    }
}

[class*='uk-width'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
}

.uk-text-left {
    text-align: left !important;
}

.uk-display-block {
    display: block !important;
}

.uk-display-inline {
    display: inline !important;
}

.uk-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.uk-margin {
    margin-bottom: 20px;
}

.uk-margin-large {
    margin-bottom: 40px;
}

*+.uk-margin-large {
    margin-top: 40px !important;
}

@media (min-width: 1200px) {
    .uk-margin-large {
        margin-bottom: 70px;
    }

    *+.uk-margin-large {
        margin-top: 70px !important;
    }
}

.uk-padding-remove {
    padding: 0 !important;
}

[hidden] {
    display: none !important;
}

@media print {

    *,
    *:before,
    *:after {
        background: transparent !important;
        color: black !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }
}

::-moz-selection {
    background: #722257 !important;
    text-shadow: none;
}

::selection {
    background: #722257 !important;
    text-shadow: none;
}

.uk-grid {
    margin-left: 0;
}

.side-bar-listing .new-arrivals button {
    border: none;
    text-align: left;
    padding: 0;
}

.side-bar-listing .new-arrivals hr {
    margin-top: 0;
}

.side-bar-listing .uk-accordion-title {
    font-size: 16px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5098);
}

.side-bar-listing .uk-fieldset label {
    color: #000000;
    margin: 4px 0;
}

.side-bar-listing .uk-fieldset input.uk-checkbox {
    margin-right: 5px;
}

.side-bar-listing .color-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.side-bar-listing .color-button button {
    border: none;
    padding: 0px;
    font-size: 19px;
    height: 30px;
    width: 30px;
    -webkit-box-shadow: 0 2px 5px #b7b7b7;
    box-shadow: 0 2px 5px #b7b7b7;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 8px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.side-bar-listing .color-button button:active {
    -webkit-box-shadow: 0 0 2px darkslategray;
    box-shadow: 0 0 2px darkslategray;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
}

.side-bar-listing .color-button .round-1 {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #FFD700;
}

.side-bar-listing .color-button .round-2 {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #F6CFCA;
}

.side-bar-listing .color-button .round-3 {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #f2f2f2;
}

.side-bar-listing .range-slider {
    width: 100%;
    text-align: center;
    position: relative;
}

.side-bar-listing .range-slider .rangeValues {
    display: block;
}

.side-bar-listing .range-slider input[type=range] {
    -webkit-appearance: none;
    border: 1px solid white;
    width: 100%;
    position: absolute;
    left: 0;
}

.side-bar-listing .range-slider input[type=range]:focus {
    outline: none;
}

.side-bar-listing .range-slider input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

.uk-modal a.uk-accordion-title {
    font-size: 16px !important;
}

.clear-all-text button.uk-button-primary {
    background: #fff;
    color: #000;
    font-size: 12px;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .uk-grid>* {
        padding-left: 5px;
    }
}