.people-section {
	background-color: #fbf8f5;
	padding: 70px 0;
}

.people-container {
	max-width: 1640px;
	margin: 0 auto;
}

.people-container .heading-section {
	max-width: 290px;
	margin: 0 auto;
	margin-bottom: 40px;
}