em {
  background: cyan;
  font-style: normal;
  font:FontGillsan !important;
}

.search h1 {
  font-size: 12px !important;
}

.search .header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #8e43e7, #00aeff);
  color: #fff;
  margin-bottom: 1rem;
}

.search .header a {
  color: #fff;
  text-decoration: none;
}

.search .header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.search .header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.search .header-subtitle {
  font-size: 1.2rem;
}

.search .container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
}
/* .search .container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.search .container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
} */

.search .search-panel {
  display: flex;
  flex-grow: 1;
}

.search .search-panel__filters {
  flex: 1;
  max-width: 213px !important;
  word-break: break-all;
}

.search .search-panel__results {
  flex: 3;
}

.search .searchbox {
  margin-bottom: 2rem;
}

.search .pagination {
  margin: 2rem auto;
  text-align: center;
}
.search .hit-image-container {
  align-items: center;
  display: flex;
  /* height: 174px; */
  margin-bottom: 1rem !important;
  justify-content: center;
  margin: auto;
  /* width: 174px; */
}
.search .hit-image {
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
.search .clear-filters {
  align-items: center;
  display: flex;
  padding-left: 1rem;
}

.search .clear-filters svg {
  margin-right: 8px;
}

.search .container-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
}
.search .container-options {
  border-bottom: 1px solid #ebecf3;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  padding: 30px 0;
}
.ais-Hits-item, .ais-InfiniteHits-item, .ais-InfiniteResults-item, .ais-Results-item {
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 1rem;
  width: calc(25% - 1rem);
  border: 1px solid #c4c8d8;
  box-shadow: 0 2px 5px 0 #e3e5ec;
}
.ais-Highlight-nonHighlighted{
  font-size: 14px !important;
  font:FontGillsan !important;
}

.productname{
  font-size: 14px !important;
  font:FontGillsan !important;
  line-height: 18px;
}

span .ais-Highlight-nonHighlighted{
  text-transform: capitalize !important;
}

