

.sweetperk-in{
    margin: 55px 0;
}

.sweetperk-in .container {
    max-width: 1300px;
}


.sweetperk-in .heading-section {
    max-width: 165px;
}

.sweetperk-in .heading-section{
    width: 100%;
    margin: 55px auto;
}


.sweetperk-in .heading-section {
    max-width: 214px;
}


.sweetperk-contents {
    display: flex;
    display: -webkit-flex;
    /* justify-content: space-between; */
}

.sweetperk-contents .sweetperk-item:not(:last-child) {
    margin-right: 2%;
}

.sweetperk-contents .sweetperk-item {
    max-width: 100%;
    width: calc((100% - 6%) / 3);
    border: 1px solid rgba(114, 34, 87, 0.5);
    padding: 2% 1.2%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {

    .sweetperk-in {
        margin: 20px 0;
    }

    .sweetperk-in .heading-section {
        margin: 20px auto;
    }
}


@media screen and (max-width: 767px) {

    .title-normal {
        font-size: 24px;
        line-height: 26px;
        text-align: center;
    }

    .sweetperk-contents {
        /* -webkit-flex-wrap: wrap; */
        /* -webkit-justify-content: center; */
        flex-wrap: wrap;
        justify-content: center;
    }

    .sweetperk-contents .sweetperk-item:not(:last-child) {
        margin-right: 0;
    }

    .sweetperk-contents .sweetperk-item:nth-child(even) {
        margin-left: 2%;
    }

    .sweetperk-contents .sweetperk-item {
        width: calc((100% - 3%) / 2);
        margin-bottom: 20px;
    }

}