.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-Results-list,
.ais-ToggleRefinement-list {
 margin:0;
 padding:0;
 list-style:none
}
.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-reset,
.ais-SearchBox-submit,
.ais-VoiceSearch-button {
 padding:0;
 overflow:visible;
 font:FontGillsan !important;
 line-height:normal;
 color:inherit;
 background:none;
 border:0;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-GeoSearch-redo::-moz-focus-inner,
.ais-GeoSearch-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteHits-loadPrevious::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-VoiceSearch-button::-moz-focus-inner {
 padding:0;
 border:0
}
.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-GeoSearch-redo[disabled],
.ais-GeoSearch-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteHits-loadPrevious[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-reset[disabled],
.ais-SearchBox-submit[disabled],
.ais-VoiceSearch-button[disabled] {
 cursor:default
}
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
 overflow-anchor:none
}
.ais-Breadcrumb-item,
.ais-Breadcrumb-list,
.ais-Pagination-list,
.ais-PoweredBy,
.ais-RangeInput-form,
.ais-RatingMenu-link {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -webkit-box-align:center;
 -ms-flex-align:center;
 align-items:center
}
.ais-GeoSearch,
.ais-GeoSearch-map {
 height:100%
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
 margin-left:1em
}
.ais-PoweredBy-logo {
 display:block;
 height:1.2em;
 width:auto
}
.ais-RatingMenu-starIcon {
 display:block;
 width:20px;
 height:20px
}
.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
 display:none;
 width:0;
 height:0
}
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
 display:none
}
.ais-RangeSlider .rheostat {
 overflow:visible;
 margin-top:40px;
 margin-bottom:40px
}
.ais-RangeSlider .rheostat-background {
 height:6px;
 top:0;
 width:100%
}
.ais-RangeSlider .rheostat-handle {
 margin-left:-12px;
 top:-7px
}
.ais-RangeSlider .rheostat-background {
 position:relative;
 background-color:#fff;
 border:1px solid #aaa
}
.ais-RangeSlider .rheostat-progress {
 position:absolute;
 top:1px;
 height:4px;
 background-color:#333
}
.rheostat-handle {
 position:relative;
 z-index:1;
 width:20px;
 height:20px;
 background-color:#fff;
 border:1px solid #333;
 border-radius:50%;
 cursor:-webkit-grab;
 cursor:grab
}
.rheostat-marker {
 margin-left:-1px;
 position:absolute;
 width:1px;
 height:5px;
 background-color:#aaa
}
.rheostat-marker--large {
 height:9px
}
.rheostat-value {
 padding-top:15px
}
.rheostat-tooltip,
.rheostat-value {
 margin-left:50%;
 position:absolute;
 text-align:center;
 -webkit-transform:translateX(-50%);
 transform:translateX(-50%)
}
.rheostat-tooltip {
 top:-22px
}
[class^=ais-] {
 font-size:1rem;
 box-sizing:border-box
}
a[class^=ais-] {
 text-decoration:none
}
.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-GeoSearch,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-HitsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-Results,
.ais-ResultsPerPage,
.ais-SearchBox,
.ais-SortBy,
.ais-Stats,
.ais-ToggleRefinement {
 color:#3a4570
}
.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
 font-weight:700
}
.ais-Breadcrumb-separator {
 margin:0 .3em;
 font-weight:400
}
.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
 color:#0096db;
 transition:color .2s ease-out
}
.ais-Breadcrumb-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link:focus,
.ais-Menu-link:hover,
.ais-Pagination-link:focus,
.ais-Pagination-link:hover,
.ais-RatingMenu-link:focus,
.ais-RatingMenu-link:hover {
 color:#0073a8
}
.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
 padding:.3rem .5rem;
 font-size:.8rem;
 color:#fff;
 background-color:#722257;
 border-radius:5px;
 transition:background-color .2s ease-out;
 outline:none
}
.ais-ClearRefinements-button:focus,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset:focus,
.ais-CurrentRefinements-reset:hover,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:focus,
.ais-GeoSearch-reset:hover,
.ais-HierarchicalMenu-showMore:focus,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteHits-loadPrevious:focus,
.ais-InfiniteHits-loadPrevious:hover,
.ais-InfiniteResults-loadMore:focus,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore:focus,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:focus,
.ais-RefinementList-showMore:hover {
 background-color:#ffff;
 color: #722257;
}
.ais-ClearRefinements-button--disabled,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-HierarchicalMenu-showMore--disabled,
.ais-InfiniteHits-loadMore--disabled,
.ais-InfiniteResults-loadMore--disabled,
.ais-Menu-showMore--disabled,
.ais-RefinementList-showMore--disabled {
 opacity:.3;
 cursor:not-allowed
}
.ais-ClearRefinements-button--disabled:focus,
.ais-ClearRefinements-button--disabled:hover,
.ais-GeoSearch-redo--disabled:focus,
.ais-GeoSearch-redo--disabled:hover,
.ais-GeoSearch-reset--disabled:focus,
.ais-GeoSearch-reset--disabled:hover,
.ais-HierarchicalMenu-showMore--disabled:focus,
.ais-HierarchicalMenu-showMore--disabled:hover,
.ais-InfiniteHits-loadMore--disabled:focus,
.ais-InfiniteHits-loadMore--disabled:hover,
.ais-InfiniteResults-loadMore--disabled:focus,
.ais-InfiniteResults-loadMore--disabled:hover,
.ais-Menu-showMore--disabled:focus,
.ais-Menu-showMore--disabled:hover,
.ais-RefinementList-showMore--disabled:focus,
.ais-RefinementList-showMore--disabled:hover {
 background-color:#0096db
}
.ais-InfiniteHits-loadPrevious--disabled {
 display:none
}
.ais-CurrentRefinements {
 margin-top:-.3rem
}
.ais-CurrentRefinements,
.ais-CurrentRefinements-list {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap
}
.ais-CurrentRefinements-item {
 margin-right:.3rem;
 margin-top:.3rem;
 padding:.3rem .5rem;
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 background-color:#495588;
 border-radius:5px
}
.ais-CurrentRefinements-category {
 margin-left:.3em;
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex
}
.ais-CurrentRefinements-delete {
 margin-left:.3rem
}
.ais-CurrentRefinements-categoryLabel,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-label {
 white-space:nowrap;
 font-size:.8rem;
 color:#fff
}
.ais-CurrentRefinements-reset {
 margin-top:.3rem;
 white-space:nowrap
}
.ais-CurrentRefinements-reset+.ais-CurrentRefinements-list {
 margin-left:.3rem
}
.ais-GeoSearch {
 position:relative
}
.ais-GeoSearch-control {
 position:absolute;
 top:.8rem;
 left:3.75rem
}
.ais-GeoSearch-label {
 display:block;
 padding:.3rem .5rem;
 font-size:.8rem;
 background-color:#fff;
 border-radius:5px;
 transition:background-color .2s ease-out;
 box-shadow:0 1px 1px rgba(0,0,0,.1);
 outline:none
}
.ais-GeoSearch-input {
 margin:0 .25rem 0 0
}
.ais-GeoSearch-label,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
 white-space:nowrap
}
.ais-GeoSearch-reset {
 position:absolute;
 bottom:1.25rem;
 left:50%;
 -webkit-transform:translateX(-50%);
 transform:translateX(-50%)
}
.ais-HierarchicalMenu-link,
.ais-Menu-link {
 display:block;
 line-height:1.5
}
.ais-HierarchicalMenu-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-RatingMenu-list,
.ais-RefinementList-list {
 font-weight:400;
 line-height:1.5
}
.ais-HierarchicalMenu-link:after {
 margin-left:.3em;
 content:"";
 width:10px;
 height:10px;
 display:none;
 background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.3 24l-2.8-2.8 9.3-9.2-9.3-9.2L7.3 0l12.2 12z' fill='%233A4570'/%3E%3C/svg%3E");
 background-size:100% 100%
}
.ais-HierarchicalMenu-item--parent>.ais-HierarchicalMenu-link:after {
 display:inline-block
}
.ais-HierarchicalMenu-item--selected>.ais-HierarchicalMenu-link:after {
 -webkit-transform:rotate(90deg);
 transform:rotate(90deg)
}
.ais-CurrentRefinements-count,
.ais-RatingMenu-count {
 font-size:.8rem
}
.ais-CurrentRefinements-count:before,
.ais-RatingMenu-count:before {
 content:"("
}
.ais-CurrentRefinements-count:after,
.ais-RatingMenu-count:after {
 content:")"
}
.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
 padding:.1rem .4rem;
 font-size:.8rem;
 color:#3a4570;
 background-color:#dfe2ee;
 border-radius:8px
}
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
 margin-top:.5rem
}
.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
 background-color:#4b4b4b38 ;
}
.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Results-list {
 margin-top:-1rem;
 margin-left:-1rem;
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap
}


  @media (min-width: 260px) {
    .ais-Hits-item {
        width: calc(100% - 1rem) !important;
    }
  }
  @media (min-width: 680px) {
    .ais-Hits-item {
        width: calc(50% - 1rem) !important;
    }
  }

  @media (min-width: 900px) {
    .ais-Hits-item {
        width: calc(50% - 1rem) !important;
    }
  }

  @media (min-width: 1200px) {
    .ais-Hits-item {
        width: calc(25% - 1rem) !important;
    }
  }


.ais-Panel-body .ais-Hits-list,
.ais-Panel-body .ais-InfiniteHits-list,
.ais-Panel-body .ais-InfiniteResults-list,
.ais-Panel-body .ais-Results-list {
 margin:.5rem 0 0 -1rem
}
.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
 margin-top:1rem;
 margin-left:1rem;
 padding:1rem;
 width:calc(25% - 1rem);
 border:1px solid #c4c8d8;
 box-shadow:0 2px 5px 0 #e3e5ec
}
.ais-Panel-body .ais-Hits-item,
.ais-Panel-body .ais-InfiniteHits-item,
.ais-Panel-body .ais-InfiniteResults-item,
.ais-Panel-body .ais-Results-item {
 margin:.5rem 0 .5rem 1rem
}
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore {
 margin-top:1rem
}
.ais-InfiniteHits-loadPrevious {
 margin-bottom:1rem
}
.ais-HitsPerPage-select,
.ais-MenuSelect-select,
.ais-NumericSelector-select,
.ais-ResultsPerPage-select,
.ais-SortBy-select {
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none;
 padding:.3rem 2rem .3rem .3rem;
 max-width:100%;
 background-color:#fff;
 background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 7.3l2.8-2.8 9.2 9.3 9.2-9.3L24 7.3 12 19.5z' fill='%233A4570'/%3E%3C/svg%3E");
 background-repeat:no-repeat;
 background-size:10px 10px;
 background-position:92% 50%;
 border:1px solid #c4c8d8;
 border-radius:5px
}
.ais-Panel--collapsible {
 position:relative
}
.ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsible.ais-Panel--collapsed .ais-Panel-footer {
 display:none
}
.ais-Panel--collapsible .ais-Panel-collapseButton {
 position:absolute;
 top:0;
 right:0;
 padding:0;
 border:none;
 background:none
}
.ais-Panel-header {
 margin-bottom:.5rem;
 padding-bottom:.5rem;
 font-size:.8rem;
 font-weight:700;
 text-transform:uppercase;
 border-bottom:1px solid #c4c8d8
}
.ais-Panel-footer {
 margin-top:.5rem;
 font-size:.8rem
}
.ais-RangeInput-input {
 padding:0 .2rem;
 width:5rem;
 height:1.5rem;
 line-height:1.5rem
}
.ais-RangeInput-separator {
 margin:0 .3rem
}
.ais-RangeInput-submit {
 margin-left:.3rem;
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none;
 padding:0 .5rem;
 height:1.5rem;
 line-height:1.5rem;
 font-size:.8rem;
 color:#fff;
 background-color:#0096db;
 border:none;
 border-radius:5px;
 transition:.2s ease-out;
 outline:none
}
.ais-RangeInput-submit:focus,
.ais-RangeInput-submit:hover {
 background-color:#0073a8
}
.ais-RatingMenu-count {
 color:#3a4570
}
.ais-Pagination-list {
 -webkit-box-pack:center;
 -ms-flex-pack:center;
 justify-content:center
}
.ais-Pagination-item+.ais-Pagination-item {
 margin-left:.3rem
}
.ais-Pagination-link {
 padding:.3rem .6rem;
 display:block;
 border:1px solid #c4c8d8;
 border-radius:5px;
 transition:background-color .2s ease-out
}
.ais-Pagination-link:focus,
.ais-Pagination-link:hover {
 background-color:#e3e5ec
}
.ais-Pagination-item--disabled .ais-Pagination-link {
 opacity:.6;
 cursor:not-allowed;
 color:#a5abc4
}
.ais-Pagination-item--disabled .ais-Pagination-link:focus,
.ais-Pagination-item--disabled .ais-Pagination-link:hover {
 color:#a5abc4;
 background-color:#fff
}
.ais-Pagination-item--selected .ais-Pagination-link {
 color:#fff;
 background-color:#0096db;
 border-color:#0096db
}
.ais-Pagination-item--selected .ais-Pagination-link:focus,
.ais-Pagination-item--selected .ais-Pagination-link:hover {
 color:#fff
}
.ais-PoweredBy-text,
.ais-Stats-text,
.rheostat-tooltip,
.rheostat-value {
 font-size:.8rem
}
.ais-PoweredBy-logo {
 margin-left:.3rem
}
.ais-RangeSlider .rheostat-progress {
 background-color:#495588
}
.ais-RangeSlider .rheostat-background {
 border-color:#878faf;
 box-sizing:border-box
}
.ais-RangeSlider .rheostat-handle {
 border-color:#878faf
}
.ais-RangeSlider .rheostat-marker {
 background-color:#878faf
}
.ais-Panel-body .ais-RangeSlider {
 margin:2rem 0
}
.ais-RangeSlider-handle {
 width:20px;
 height:20px;
 position:relative;
 z-index:1;
 background:#fff;
 border:1px solid #46aeda;
 border-radius:50%;
 cursor:pointer
}
.ais-RangeSlider-tooltip {
 position:absolute;
 background:#fff;
 top:-22px;
 font-size:.8em
}
.ais-RangeSlider-value {
 width:40px;
 position:absolute;
 text-align:center;
 margin-left:-20px;
 padding-top:15px;
 font-size:.8em
}
.ais-RangeSlider-marker {
 position:absolute;
 background:#ddd;
 margin-left:-1px;
 width:1px;
 height:5px
}
.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
 color:#c4c8d8
}
.ais-RatingMenu-item--selected {
 font-weight:700
}
.ais-RatingMenu-link {
 line-height:1.5
}
.ais-RatingMenu-link>*+* {
 margin-left:.3rem
}
.ais-RatingMenu-starIcon {
 position:relative;
 top:-1px;
 width:15px;
 fill:#ffc168
}
.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
 fill:#c4c8d8
}
.ais-HierarchicalMenu-searchBox>*,
.ais-Menu-searchBox>*,
.ais-RefinementList-searchBox>* {
 margin-bottom:.5rem
}
.ais-SearchBox-form {
 display:block;
 position:relative
}
.ais-SearchBox-input {
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none;
 padding:.3rem 1.7rem;
 width:100%;
 position:relative;
 background-color:#fff;
 border:1px solid #c4c8d8;
 border-radius:5px
}
.ais-SearchBox-input::-webkit-input-placeholder {
 color:#a5aed1
}
.ais-SearchBox-input::-moz-placeholder {
 color:#a5aed1
}
.ais-SearchBox-input:-ms-input-placeholder {
 color:#a5aed1
}
.ais-SearchBox-input:-moz-placeholder {
 color:#a5aed1
}
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset,
.ais-SearchBox-submit {
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none;
 position:absolute;
 z-index:1;
 width:20px;
 height:20px;
 top:50%;
 right:.3rem;
 -webkit-transform:translateY(-50%);
 transform:translateY(-50%)
}
.ais-SearchBox-submit {
 left:.3rem
}
.ais-SearchBox-reset {
 right:.3rem
}
.ais-SearchBox-loadingIcon,
.ais-SearchBox-resetIcon,
.ais-SearchBox-submitIcon {
 position:absolute;
 top:50%;
 left:50%;
 -webkit-transform:translateX(-50%) translateY(-50%);
 transform:translateX(-50%) translateY(-50%)
}
.ais-SearchBox-resetIcon path,
.ais-SearchBox-submitIcon path {
 fill:#495588
}
.ais-SearchBox-submitIcon {
 width:14px;
 height:14px
}
.ais-SearchBox-resetIcon {
 width:12px;
 height:12px
}
.ais-SearchBox-loadingIcon {
 width:16px;
 height:16px
}
.ais-VoiceSearch-button {
 border:none;
 width:24px;
 height:24px;
 padding:4px;
 border-radius:50%;
 color:#3a4570;
 background-color:transparent
}
.ais-VoiceSearch-button svg {
 color:currentColor
}
.ais-VoiceSearch-button:hover {
 cursor:pointer;
 background-color:#a5aed1;
 color:#fff
}
.ais-VoiceSearch-button:disabled {
 color:#a5aed1
}
.ais-VoiceSearch-button:disabled:hover {
 color:#a5aed1;
 cursor:not-allowed;
 background:inherit
}


.ais-RefinementList-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 2px;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
  position: relative;
  width: 1rem;
}

.ais-RefinementList-item--selected {
  font-weight: bold;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  background-color: #722257;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
  background-color: #fff;
  border-radius: 4px;
  content: '';
  height: 4px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-2px) translateY(-2px);
  width: 4px;
}

.ais-RefinementList-item {
  display: block;
}

.ais-RefinementList-item,
.ais-Menu-item,
.ais-HierarchicalMenu-item,
.ais-RatingMenu-item {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ais-RefinementList-item,
/*
 The refinement list item in InstantSearch.js contains a wrapping `div` because of
 the template behavior. We therefore need to apply the styles to all the elements
 in a refinement list.
*/
.ais-RefinementList-item *,
.ais-RatingMenu-item {
  cursor: pointer;
}

.ais-HierarchicalMenu-link,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  padding-bottom: 1rem;
}


  /* Hide all mobile-specific design on desktop */

  @media (min-width: 900px) {
    [data-layout='mobile'] {
      display: none;
    }
  }


  /* View all mobile-specific desgin  */

@media (max-width: 899px) {
    /* Filters overlay */
  
    .container-filters {
      background: #fff;
      border-radius: 16px;
      left: 0;
      max-width: initial;
      padding-bottom: 0rem;
      position: fixed;
      top: 0;
      transform: translateY(120vh);
      transition: transform 300ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
      width: 100%;
      will-change: transform;
      z-index: 1;
    }


    .container-filters .container-header,
    .container-filters .container-body {
      padding: 2rem 2rem 0 2rem;
    }
  
    .filtering .header {
      /*
        Closing panel on outter click didn't work on mobile safari.
        This is one of the workarounds from the following:
        https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event#Safari_Mobile
      */
      cursor: pointer;
    }
  
    .filtering .header-logo {
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 1.5rem;
      transform: translateX(-50%);
    }
  
    .filtering .header-title,
    .filtering .container-results,
    .container-filters-footer {
      display: none;
    }
  
    .filtering .container-filters {
      position: absolute;
      transform: translateY(4rem);
    }
  
    .filtering .container-filters-footer {
      background-color: #fff;
      border-top: 1px solid #ebecf3;
      bottom: 3rem;
      display: flex;
      justify-content: space-between;
      margin: 0 -21px;
      padding: 1rem;
      position: fixed;
      width: 100%;
      z-index: 5; /* avoid collision with slider UI */
    }
  
    .container-filters-footer-button-wrapper {
      width: calc(50% - 0.5rem);
    }
  
    .container-filters-footer .ais-ClearRefinements-button,
    .container-filters-footer .button {
      background-color: #722577;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      font: inherit;
      font-size: 0.75rem;
      font-weight: bold;
      justify-content: center;
      padding: 12px;
      text-align: center;
      width: 100%;
    }
  
    .container-filters-footer .button-primary {
      background-color: #e2a400;
      color: #fff;
    }
  
    /* Filters button that triggers the overlay */
  
    .filtering .filters-button {
      display: flex;
    }
  
    .filters-button {
      align-items: center;
      background-color: #722257;
      border: none;
      border-radius: 8px;
      bottom: 4rem;
      /* box-shadow: 0 4px 22px 0 rgba(185, 135, 0, 0.5); */
      color: #fff;
      cursor: pointer;
      display: flex;
      font: inherit;
      font-size: 0.875rem;
      font-weight: bold;
      justify-content: center;
      left: 50%;
      min-height: 40px;
      min-width: 112px;
      position: fixed;
      transform: translateX(-50%);
    }
  
    .filters-button svg {
      height: 14px;
      margin-right: 8px;
      width: 16px;
    }
  
    .container {
      padding: 0rem;
    }
  
    .container-options {
      display: none;
    }
  
    /* .header {
      background: linear-gradient(to bottom, #ae8600, #885b01);
      background-image: url('./assets/cover-mobile.png');
      background-image: url('./assets/cover-mobile.png'),
        linear-gradient(to bottom, #ae8600, #885b01);
      background-position: bottom;
      background-size: cover;
      min-height: 300px;
      position: relative;
      transition: transform 200ms ease-out;
    } */
  
    /* SearchBox */
  
    .header .ais-SearchBox {
      bottom: 0;
      left: 0;
      position: absolute;
      transform: translateY(50%);
      width: 100vw;
    }
  
    .header .ais-SearchBox .ais-SearchBox-form {
      margin: auto;
      max-width: 90%;
    }
  
    .ais-SearchBox .ais-SearchBox-input,
    .ais-RefinementList .ais-SearchBox-input {
      font-size: 1rem;
    }
  
    .ais-RefinementList .ais-SearchBox-input {
      min-height: 48px;
    }
  
    /* RefinementList */
  
    .ais-RefinementList-list {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0 2rem;
      grid-template-rows: repeat(5, 1fr);
    }
  
    .ais-RefinementList-item {
      flex: 50%;
    }
  
    .ais-RefinementList-checkbox {
      height: 1.5rem;
      min-width: 1.5rem;
    }
  
    .ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
      align-items: center;
      background: none;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='9'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h24v24H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-6 -8)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23fff' fill-rule='nonzero' d='M16.5 8.5L18 10l-6.99 7-4.51-4.5L8 11l3.01 3z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
      display: flex;
      height: 100%;
      justify-content: center;
      left: initial;
      position: relative;
      top: initial;
      transform: initial;
      width: initial;
    }
  
    /* HierarchicalMenu */
  
    .ais-HierarchicalMenu-link::before {
      background-color: rgba(65, 66, 71, 0.08);
      border-radius: 50%;
      padding: 8px;
    }
  
    /* ToggleRefinement */
  
    .ais-ToggleRefinement-checkbox {
      min-width: 47px;
      position: relative;
    }
  
    .ais-ToggleRefinement-checkbox {
      margin-left: 2rem;
    }
  
    .ais-ToggleRefinement-checkbox::after {
      height: 1.5rem;
      top: -4px;
      width: 1.5rem;
    }
  
    .ais-ToggleRefinement-checkbox::before {
      right: 54px;
    }
  
    /* RatingMenu */
  
    .ais-RatingMenu-starIcon {
      height: 1.5rem;
      margin-right: 1rem;
      width: 1.5rem;
    }
  
    /* Hits */
  
    /* .ais-Hits-list {
      grid-gap: 1rem;
    } */
  
    .hit {
      display: flex;
    }
  
    .hit-image-container {
      flex: 1;
      margin-right: 2rem;
    }
  
    .hit-info-container {
      flex: 2;
    }
  
    .hits-empty-state-image {
      display: none;
    }
  
    /* Hide all desktop-specific design on mobile */
  
    [data-layout='desktop'] {
      display: none;
    }
  }
  