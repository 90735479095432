
.about-us-section .heading-section {
	max-width: -webkit-fit-content;
	max-width: fit-content;
	width: 100%;
	margin: 22px 0 0;
}

.title-normal {
    font-size: 35px;
    line-height: 43px;
    color: #505050;
    font-family: bigCalsonMedium;
    font-weight: 400;
}

.heading-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 15px;
}


.diamond-icon {
    background: url(/assets/images/new_template/images/Icon_jewelry.svg) no-repeat center;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 15px;
}


.aboutus-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;
    justify-content: center;
}

