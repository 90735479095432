.featured-in,
.news-feed {
    margin: 55px 0;
}

.featured-in .home-container {
    max-width: 1300px;
}


.featured-in .heading-section {
    max-width: 190px;
}

.featured-in .heading-section,
.news-feed .heading-section {
    width: 100%;
    margin: 55px auto;
}

.featured-contents {
    /* display: flex; */
    display: -webkit-flex;
}

.featured-contents .featured-item:not(:last-child) {
    margin-right: 2%;
}

.featured-contents .featured-item:hover {
    border-color: #722257;
}

.featured-contents .featured-item:hover img {
    opacity: 1;
}

.featured-contents .featured-item {
    max-width: 100%;
    width: calc((100% - 6%) / 4);
    border: 1px solid rgba(114, 34, 87, 0.5);
    padding: 7% 1.2%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.featured-contents .featured-item img {
    margin: auto;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: block;
}



@media screen and (max-width: 1023px) {

    .featured-in,
    .news-feed {
        margin: 20px 0;
    }

    .featured-in .heading-section,
    .news-feed .heading-section {
        margin: 20px auto;
    }
}


@media screen and (max-width: 767px) {

    .title-normal {
        font-size: 24px !important;
        line-height: 26px;
        text-align: center;
    }

    .featured-contents {
        /* -webkit-flex-wrap: wrap; */
        /* -webkit-justify-content: center; */
        flex-wrap: wrap;
        justify-content: center;
    }

    .featured-contents .featured-item:not(:last-child) {
        margin-right: 0;
    }

    .featured-contents .featured-item:nth-child(even) {
        margin-left: 2%;
    }

    .featured-contents .featured-item {
        width: calc((100% - 3%) / 2);
        margin-bottom: 20px;
    }

}