.promise-section {
	background-color: #fbf8f5;
	padding: 70px 20px;
	margin-bottom: 80px;
}

.promise-container {
	max-width: 1547px;
	margin: 0 auto;
}

.promise-container .heading-section {
	max-width: 402px;
	margin: 0 auto;
}

.promise-content {
	display: flex;
	flex-wrap: wrap;
	margin: 37px -15px 0;
	justify-content: center;
}

.promise-content a {
	text-align: center;
	border: 1px solid #b68a2d;
	box-shadow: 2px 4px 5px #b68a2d;
	padding: 20px;
	vertical-align: middle;
	max-height: 180px;
	font-size: 12px;
	max-width: 140px;
	width: 100%;
	margin: 0 15px 30px;
	transition: all 0.3s ease-in-out;
}

a {
	text-decoration: none;
}

.promise-content a:hover {
	-webkit-transform: scale(1.08);
	transform: scale(1.1);
	border: 0;
	box-shadow: 0 0 6px rgba(0, 0%, 0%, 8%);
}

.promise-content img {
	width: 60px;
	height: 60px;
}

.promise-content span {
	display: block;
	width: 100px;
	margin: 25px auto 0;
}

@media screen and (max-width: 767px) {

	.promise-section,
	section.gift-ideas-section {
		margin-bottom: 25px;
	}
}