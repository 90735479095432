.my_l {
    margin: 90px 0;
}

.putting-glitz .home-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

/*Glitz left-side*/

/*Title css */

.behind-scenes .behind-title .para-one,
.putting-glitz .glitz-top .para-one {
    font-size: 14px !important;
    letter-spacing: 2.8px;
    color: #000;
    text-transform: uppercase;
    font-family: gillSansMedium !important;
}

.behind-scenes .heading-section,
.putting-glitz .heading-section {
    max-width: fit-content;
    width: 100%;
    margin: 22px 0 0;
    margin-bottom: 14.4%;
}

.putting-glitz .bottom-border,
.trending-products .bottom-border {
    width: 100%;
    max-width: 100%;
    position: relative;
    height: 4px;
    background-color: #e3e3e3;
    margin-top: 40px;
}

.putting-glitz .bottom-border span {
    left: 0;
}

.putting-glitz .bottom-border span {
    width: 26.666%;
    max-width: 26.666%;
    height: 100%;
    max-height: 100%;
    background-color: #722257;
    position: absolute;
    display: inline-block;
    top: 0;
}


/*Slider css*/

.putting-glitz .glitz-img {
    background: #fbf8f5;
    background-blend-mode: saturation;
}

.putting-glitz .glitz-img img {
    mix-blend-mode: inherit;
}

.putting-glitz .glitz-content .para-one {
    margin: 20px 0 10px;
    line-height: 26px;
}

.putting-glitz .new-price,
.trending-products .new-price {
    font-family: gillSansBold;
    color: #505050;
}

.putting-glitz .price span::before,
.trending-products .price span::before {
    content: "\20B9";
}

.putting-glitz .old-price,
.trending-products .old-price {
    color: #c4c4c4;
    text-decoration: line-through;
}

.glitz-item {
    padding: 15px
}

/*Glitz right-side*/

.putting-glitz .home-container>div {
    max-width: 45.732%;
    width: 100%;
}

.glitz-right img {
    height: 100%;
    max-height: 100%;
}

.arrow {
    position: absolute;
    font-size: 2.5em;
    cursor: pointer;
    /* color: #722257; */
}

.nextArrow {
    top: 30%;
    right: -4%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(90deg);
}

.prevArrow {
    top: 30%;
    left: -4%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(270deg);
}


@media screen and (max-width: 1023px) {
    .my_l {
        margin: 40px 0;
    }

    .putting-glitz .home-container {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .putting-glitz .home-container>div {
        max-width: 100%;
    }

    .putting-glitz .bottom-border {
        margin: 30px 0;
    }
}

@media screen and (max-width: 767px) {

    .my_l {
        margin: 25px 0;
    }

    .nextArrow {
        right: -2%;
    }
    
    .prevArrow {
        left: -2%;
    }
}