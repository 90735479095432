.behind-scenes .home-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.behind-scenes .home-container>div {
    max-width: 45.732%;
    width: 100%;
}

.behind-scenes .heading-section {
    margin-bottom: 48px;
}

.behind-scenes .heading-section,
.putting-glitz .heading-section {
    max-width: fit-content;
    width: 100%;
}

.behind-scenes .behind-content {
    max-width: 97%;
    width: 100%;
}

.behind-scenes .normal-para:first-child {
    margin-bottom: 30px;
}

.behind-scenes .normal-para {
    font-size: 18px;
    color: #707070;
}

.normal-para {
    line-height: 26px;
}

.behind-scenes .behind-images {
    display: -webkit-flex;
    display: flex;
    margin-top: 43px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.behind-scenes .behind-images>div:not(:last-child) {
    margin-right: 3.5%;
}

.behind-scenes .behind-images>div {
    max-width: 31%;
    width: 100%;
}

.behind-scenes .behind-images img {
    width: 100%;
}

@media screen and (max-width: 1023px) {

    .behind-scenes .home-container {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .behind-scenes .home-container>div {
        max-width: 100%;
    }

    .behind-scenes .behind-left {
        order: 2;
        margin-top: 30px;
    }

    .behind-scenes .behind-content {
        max-width: 100%;
    }

    .behind-left img,
    .glitz-right img {
        max-width: 80%;
        margin: auto;
        display: block;
    }
}


@media screen and (max-width: 1300px) {
    .behind-scenes .behind-content {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .title-normal {
        font-size: 24px;
        line-height: 26px;
        text-align: center;
    }

    .behind-scenes .behind-content {
        max-width: 100%;
    }
}