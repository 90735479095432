.newsfeed-section .newsfeed-text {
	position: absolute;
	bottom: 10%;
	/* width: 100%; */
	max-width: 100%;
	padding: 0 30px;
	z-index: 1;
	-webkit-transition: all 0.3s ease;
	transition: opacity 0.3s ease;
}

.newsfeed-section .normal-para {
	opacity: 0.84;
	color: #fff !important;
}

.new-trend .newsfeed-text .title-medium {
	height: 85px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 20px;
	color: #fff !important;
}


.title-medium {
	font-size: 28px;
	line-height: 34px;
	font-family: bigCalsonMedium !important;
	font-weight: 400;
}


.newsfeed-section .overlay-content {
	text-align: center;
	z-index: 2;
	position: absolute;
	background-color: #fbf8f5;
	/* max-width: 100px;
	width: 100%; */
	top: 22%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	padding: 20% 4%;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}

.newsfeed-section .overlay-content span {
	background: url(/assets/images/new_template/images/ArrowRight.svg) no-repeat center/contain;
	display: inline-block;
	width: 41px;
}

.newsfeed-section .overlay-content .title-medium {
	color: #722257;
	margin: 8px 0 30px;
}

.newsfeed-section .newsfeed-item::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 35px;
	background-image: linear-gradient(180deg, rgba(36, 47, 64, 0), rgba(114, 34, 87, 0.5));
}


.newsfeed-section .newsfeed-item:hover .overlay-content {
	opacity: 0.8;
	visibility: visible;
}

.newsfeed-section .newsfeed-item:hover .newsfeed-text {
	opacity: 0;
	visibility: hidden;
}

.newsfeed-item {
	padding: 35px;
}

.new-trend .overlay-content {
	padding: 20% 0%;
}


@media screen and (max-width: 767px) {

	.choose-style .choose-title,
	.discover-homepage .discover-title,
	.new-trend .trend-title {
		margin-bottom: 15px;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	}

	.new-trend .title-normal {
		font-size: 12px;
		line-height: 18px;
		text-align: left;
	}

	.newsfeed-item {
		padding: 0px;
	}

	.newsfeed-section .newsfeed-item::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0px;
		background-image: linear-gradient(180deg, rgba(36, 47, 64, 0), rgba(114, 34, 87, 0.5));
	}
	
	
}

@media screen and (max-width: 1023px) {

	.newsfeed-section .title-medium {
		font-size: 20px;
		line-height: 28px;
		margin-top: 5px;
	}

	.newsfeed-section .newsfeed-text .title-medium {
		margin-top: 5px;
	}

	.newsfeed-section .overlay-content {
		top: 17%;
	}
}