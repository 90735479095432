.choose-style .choose-title,
.discover-homepage .discover-title,
.new-trend .trend-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}

.choose-style .heading-section,
.discover-homepage .heading-section,
.new-trend .heading-section {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: fit-content;
}

.title-normal {
    font-size: 35px !important;
    line-height: 43px;
    color: #505050;
    font-family: bigCalsonMedium !important;
    font-weight: 400;
    padding: 0;
}

.heading-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 15px;
}

.choose-title .nav-content,
.discover-title .nav-content,
.new-trend .nav-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.choose-title .para-one,
.discover-title .para-one,
.new-trend .para-one {
    font-size: 20px !important;
}

/*Blog content slider css */

.choose-item {
    padding: 15px
}


.choose-style .choose-text p {
    margin: 20px 0 0;
    font-size: 20px;
    letter-spacing: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    height: 60px;
}


.choose-style .choose-item:hover span {
    border-color: #722257;
    color: #722257;
}

.choose-style .choose-text span {
    font-size: 14px;
    letter-spacing: 3.36px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid transparent;
}

@media screen and (max-width: 767px) {

    .choose-style .title-normal,
    .discover-homepage .heading-section .title-normal,
    .new-trend .title-normal {
        font-size: 12px !important;
        line-height: 18px;
        text-align: left;
    }

    .choose-style .heading-icon,
    .discover-diamond .heading-icon,
    .new-trend .heading-icon {
        margin: 5px 0 0;
        max-width: 100%;
    }

    .choose-item {
        padding: 0px;
    }
}